.block-images {
    &__bleed {
        @each $breakpoint in map-keys($breakpoints) {
            @include from($breakpoint) {
                margin-bottom: gutter($breakpoint) * -1;
                margin-left: gutter($breakpoint) * -0.5;
                width: calc(100% + #{gutter($breakpoint)});
            }
        }
        &-image {
            @each $breakpoint in map-keys($breakpoints) {
                @include from($breakpoint) {
                    margin-bottom: gutter($breakpoint);
                }
            }
        }
    }

    &__collage {
        margin-left: -4px;
        width: calc(100% + 8px);
    }

    &__carousel {
        .flickity-viewport {
            overflow: visible;
        }

        .flickity-button {
            display: block;
            background: $colorGreen;
            color: $colorBlack;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            z-index: 2;
            transition: opacity 0.25s ease-out;
            
            &[disabled] {
                opacity: 0;
            }
        }

        .flickity-prev-next-button.previous {
            left: outerMargin(min);
            @include from(m) {
                left: outerMargin(m);
            }
            @include from(l) {
                left: outerMargin(l);
            }
            @include from(lp) {
                left: outerMargin(lp);
            }
        }

        .flickity-prev-next-button.next {
            right: outerMargin(min);
            @include from(m) {
                right: outerMargin(m);
            }
            @include from(l) {
                right: outerMargin(l);
            }
            @include from(lp) {
                right: outerMargin(lp);
            }
        }

        &-wrapper {
            position: relative;
            &:before, &:after {
                position: absolute;
                content: '';
                top: -1px;
                height: calc(100% + 2px);
                right: 100%;
                z-index: 1;
                background: linear-gradient(-90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 85%);
                .bg-black & {
                    background: linear-gradient(-90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 85%);
                }
                width: calc((100vw - (#{maxWidth(min)} + (#{outerMargin(min)}*2))) / 2);
                @each $breakpoint in map-keys($breakpoints) {
                    @include from($breakpoint) {
                        width: calc((100vw - (#{maxWidth($breakpoint)} + (#{outerMargin($breakpoint)}*2))) / 2);
                    }
                }
            }
            &:after {
                right: auto;
                left: 100%;
                transform: rotate(180deg);
            }
        }
    }

    &__captions {
        li {
            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }
    }
}
