.profundo-plug {
    &__image-wrap {
        //padding-bottom: (3/4) * 100%;
    }

    &__text-box {
        @include from(lp) {
            // margin-left: -1 * gutter('lp');
            // margin-right: -1 * gutter('lp');
        }
    }

    &__iframe-wrap {
        //margin-left: -30px;
        //margin-right: -30px;

        .js & {
            // overflow: hidden;
        }
    }

    &__iframe {
        width: 100%;
        height: 100%;
        border: 0;
        padding: 0;
        margin: 0;
        // opacity: 0;

        .js & {
            // overflow: hidden;
        }
    }
}
