.block-shorthand {
    &__media {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 0;
        overflow: hidden;
        will-change: opacity, transform;
        &:not(:first-of-type) {
            visibility: hidden;
            opacity: 0;
        }
        .js-at-top & {
            position: fixed;
        }
        .js-at-bottom & {
            position: absolute;
            top: auto;
            bottom: 0;
        }
    }

    &__text {
        height: 125vh;
        &:first-of-type {
            height: 200vh;
            padding-top: 100vh;
        }
        &:last-of-type {
            height: 150vh;
            padding-bottom: 50vh;
        }
    }
}
