.block-teaser {

    &__image-wrap-default {
        padding-bottom: (3/4) * 100%;

        @include from(m) {
            padding-bottom: (1/2) * 100%;
        }

        @include until(m) {
            transform: translateX(gutter(min) * -1);
            width: calc(100% + #{gutter(min)} * 2);
        }
    }

    &__image-wrap-bleed {
        padding-bottom: (3/4) * 100%;
        
        @include from(m) {
            padding-bottom: 0;
        }
    }

    &__box {
        .-layout-default & {
            @include until(m) {
                margin-top: -25%;
            }

            @include from(m) {
                left: 32px;
                bottom: -32px;
                width: 80%;
            }

            @include from(mp) {
                width: 60%;
            }
        }
        
        .-layout-bleed & {
            @include until(m) {
            transform: translateX(gutter(min) * -1);
            width: calc(100% + #{gutter(min)} * 2);
        }
        }
    }
}
