.newsletter-form {
    
    &__info-toggle,
    &__info-close {
        width: 50px;
        height: 50px;
        top: -25px;
    }
    
    &__info-toggle {
        left: 0;
    }
    &__info-close {
        left: -25px;
    }
    
    &__email-input {
        
    }
    
    &__info-box {
        max-width: 600px;
    }
}
