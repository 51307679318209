.block-quotes {

    &__quote {
        b, strong {
            font-weight: 500;
        }

        i, em {
            font-style: italic;
        }

        a {
            border-bottom: 2px solid $colorGreenLink;
            transition: border-bottom-color 0.25s ease-out;

            &:hover {
                border-bottom-color: transparent !important;
            }

            .bg-black & {
                border-bottom: 2px solid $colorGreen;
            }
        }
    }

    &__quote-inner {
        &.-no-image {
            .-align-default & {
                padding-left: 20px;

                @include from(m) {
                    padding-left: 36px;
                }

                &:before {
                    content: '';
                    display: block;
                    width: 4px;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background-color: $colorGreen;
                }
            }
        }

        &.-has-image {

        }
    }

    &__quote-image {
        width: 120px;
        height: 120px;
        border-radius: 50%;

        @include from(m) {
            width: 136px;
            height: 136px;
        }
    }
}
