.search {
    &:target {
        height: 100%;
    }

    &__close {
        top: 30px;
        right: gutter() - 10px;

        @include from(s) {
            right: gutter('s') - 10px;
        }
        @include from(sp) {
            right: gutter('sp') - 10px;
        }
        @include from(m) {
            right: gutter('m') - 10px;
        }
        @include from(mp) {
            right: gutter('mp') - 10px;
        }
        @include from(l) {
            top: 70px;
            right: gutter('l') - 10px;
        }
        @include from(lp) {
            right: gutter('lp') - 10px;
        }
        @include from(xl) {
            right: gutter('xl') - 10px;
        }
    }

    &__submit svg {
        width: 24px;
        height: 28px;
    }

    &__reset, &__submit {
        position: absolute;
        top: 50%;
        right: 3px;
        transform: translateY(-50%);
        padding: 0 16px;
        height: 52px;
    }

    &__field:placeholder-shown ~ &__reset {
        display: none;
    }

    &__field:not(:placeholder-shown) ~ &__submit {
        display: none;
    }
}
