.block-timeline {

    &__top-indicator {
        left: calc(50% + 12px);

        svg {
            height: 24px;
            width: 12px;
            transform: rotate(90deg);
            transform-origin: top left;
        }
    }

    &__slider {
        .flickity-viewport {
            overflow: visible;
        }

        &-item {
            &:first-child {
                .block-timeline__top-line {
                    left: gutter(min)/2;

                    @include from(s) {
                        left: gutter(s)/2;
                    }
                    @include from(sp) {
                        left: gutter(sp)/2;
                    }
                    @include from(m) {
                        left: gutter(m)/2;
                    }
                    @include from(mp) {
                        left: gutter(mp)/2;
                    }
                    @include from(l) {
                        left: gutter(l)/2;
                    }
                    @include from(lp) {
                        left: gutter(lp)/2;
                    }
                    @include from(xl) {
                        left: gutter(xl)/2;
                    }
                }
            }

            &:last-child {
                .block-timeline__top-line {
                    left: -1 * gutter(min);

                    @include from(s) {
                        left: -1 * gutter(s)/2;
                    }
                    @include from(sp) {
                        left: -1 * gutter(sp)/2;
                    }
                    @include from(m) {
                        left: -1 * gutter(m)/2;
                    }
                    @include from(mp) {
                        left: -1 * gutter(mp)/2;
                    }
                    @include from(l) {
                        left: -1 * gutter(l)/2;
                    }
                    @include from(lp) {
                        left: -1 * gutter(lp)/2;
                    }
                    @include from(xl) {
                        left: -1 * gutter(xl)/2;
                    }
                }
            }
        }
    }

    &__list {

    }

    &__side-line {
        @include from(l) {
            left: -44px;
        }
    }

    &__side-indicator {
        top: calc(50% - 20px - 12px);
        
        .block-timeline__list-item:last-child & {
            top: calc(50% - 12px);
        }

        @include from(l) {
            left: -44px;
        }

        svg {
            height: 24px;
            width: 12px;
            transform-origin: top left;
        }
    }

}
