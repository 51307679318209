$breakpointsUnit: px;

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}

// Converts a px (or unitless px) value to the desired unit type for the breakpoints
@function convertBreakpointUnit($value, $unit: $breakpointsUnit) {
    @if $unit == 'em' {
        @return pxToEm($value);
    } @else if $unit == 'rem' {
        @return pxToRem($value);
    }
    @return $value + 0px;
}

@function get-bp-value($bp, $bpMap: $breakpoints ) {
    @if map-has-key($bpMap, $bp) {
        @return map-get($bpMap, $bp);
    } @else {
        @return $bp;
    }
}
// For custom media queries - see http://breakpoint-sass.com
@mixin from($bp, $unit: $breakpointsUnit) {
    $bp: convertBreakpointUnit(get-bp-value($bp));

    @include breakpoint($bp) {
        @content;
    }
}

@mixin between($bpMin, $bpMax, $unit: $breakpointsUnit) {
    $bpMin: convertBreakpointUnit(get-bp-value($bpMin));
    $bpMax: convertBreakpointUnit(get-bp-value($bpMax) - 1);
    @include breakpoint($bpMin $bpMax) {
        @content;
    }
}

@mixin until($bp, $unit: $breakpointsUnit) {
    $bp: convertBreakpointUnit(get-bp-value($bp) - 1);
    @include breakpoint(max-width $bp) {
        @content;
    }
}

@mixin bigger-than($bp, $unit: $breakpointsUnit) {
    @include from($bp, $unit) {
        @content;
    }
}

@mixin smaller-than($bp, $unit: $breakpointsUnit) {
    @include until($bp, $unit) {
        @content;
    }
}

@mixin breakpointer($breakpoints: $breakpoints) {

    body::after {
        display: none;
        position: relative;
        z-index: 1;

        @each $key, $val in $breakpoints {

            @include from($val) {
                content: '#{$key}';
                z-index: strip-unit($val);
            }
        }
    }
}
