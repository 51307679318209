.pagination {
    &__button {
        display: inline-flex;
        align-content: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border: 1px solid transparent;
        transition: border 0.2s ease-out;
        &:hover, &:focus {
            border: 1px solid $colorGrayStone;
        }
        @include from(l) {
            width: 40px;
        }
    }

    &__page {
        display: inline-flex;
        align-content: center;
        justify-content: center;
        width: 33px;
        height: 36px;
        line-height: 34px;
        margin: 0 4px;
        border: 1px solid transparent;
        transition: border 0.2s ease-out;
        @include from(l) {
            width: 38px;
        }
        &:hover, &:focus {
            border: 1px solid $colorGrayStone;
        }
        &.-selected {
            background: $colorBlack;
            color: $colorWhite;
            border: 1px solid $colorBlack;
        }
        &.-hidden:not(:focus) {
            width: 0;
            height: 0;
            border: 0;
            margin: 0;
            overflow: hidden;
        }
    }
}
