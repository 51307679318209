.block-divider {
    height: 2px;
    border: 0;
    background-color: $colorBlack;
    &--tribal {
        height: 8px;
        background-color: transparent;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 0H0L4 8L8 0Z' fill='currentColor'/%3E%3Cpath d='M8 8H16L12 0L8 8Z' fill='currentColor'/%3E%3C/svg%3E");
        background-repeat: repeat-x;
    }

    .bg-black & {
        filter: invert(100%);
    }
}


