/*

    @font-face declarations

    Paths to url() should be relative to the SASS entrypoint (base.scss), i.e. "../fonts/fontname.woff2"

 */

@layer base {

    @font-face {
        font-family: 'Falster';
        font-weight: normal;
        font-style: normal;
        font-display: swap;
        src: url('../fonts/BC-Falster-Grotesk-Regular-5eff2d62beb194306abca5585932f2b5.woff2') format('woff2'),
        url('../fonts/BC-Falster-Grotesk-Regular-5eff2d62beb194306abca5585932f2b5.woff') format('woff');
    }

    @font-face {
        font-family: 'Falster';
        font-weight: normal;
        font-style: italic;
        font-display: swap;
        src: url('../fonts/BC-Falster-Grotesk-Italic-a6f1db333b76d286f039f39dceb7cc33.woff2') format('woff2'),
        url('../fonts/BC-Falster-Grotesk-Italic-a6f1db333b76d286f039f39dceb7cc33.woff') format('woff');
    }

    @font-face {
        font-family: 'Falster';
        font-weight: 500;
        font-style: normal;
        font-display: swap;
        src: url('../fonts/BC-Falster-Grotesk-Medium-2e64c789f477f6c70ff6d366f4e53e7c.woff2') format('woff2'),
        url('../fonts/BC-Falster-Grotesk-Medium-2e64c789f477f6c70ff6d366f4e53e7c.woff') format('woff');
    }

    @font-face {
        font-family: 'Falster';
        font-weight: 500;
        font-style: italic;
        font-display: swap;
        src: url('../fonts/BC-Falster-Grotesk-Medium-Italic-87c5f229178c7a1f8e2a5fccc769d26f.woff2') format('woff2'),
        url('../fonts/BC-Falster-Grotesk-Medium-Italic-87c5f229178c7a1f8e2a5fccc769d26f.woff') format('woff');
    }

    @font-face {
        font-family: 'Fabriga';
        font-weight: normal;
        font-style: normal;
        font-display: swap;
        src: url('../fonts/Fabriga-Regular.woff2') format('woff2'),
        url('../fonts/Fabriga-Regular.woff') format('woff');
    }

    @font-face {
        font-family: 'Fabriga';
        font-weight: normal;
        font-style: italic;
        font-display: swap;
        src: url('../fonts/Fabriga-Italic.woff2') format('woff2'),
        url('../fonts/Fabriga-Italic.woff') format('woff');
    }

    @font-face {
        font-family: 'Fabriga';
        font-weight: 500;
        font-style: normal;
        font-display: swap;
        src: url('../fonts/Fabriga-Medium.woff2') format('woff2'),
        url('../fonts/Fabriga-Medium.woff') format('woff');
    }

    @font-face {
        font-family: 'Fabriga';
        font-weight: 700;
        font-style: normal;
        font-display: swap;
        src: url('../fonts/Fabriga-Bold.woff2') format('woff2'),
        url('../fonts/Fabriga-Bold.woff') format('woff');
    }
    @font-face {
        font-family: 'Fabriga';
        font-weight: 700;
        font-style: italic;
        font-display: swap;
        src: url('../fonts/Fabriga-BoldItalic.woff2') format('woff2'),
        url('../fonts/Fabriga-BoldItalic.woff') format('woff');
    }
}
