.page-footer {
    &__inner-wrap {
        max-width: none !important;
        
        @include from(lp) {
            padding-left: 80px - 16px;
            padding-right: 80px - 16px;
        }
    }
    
    &__info {
        a {
            color: $colorGreen;
            border-bottom: 1px solid $colorGreen;
        }
    }
}
