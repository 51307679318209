/**
========================
Custom Tailwind utilities
https://tailwindcss.com/docs/adding-new-utilities
========================

All Tailwind-powered utilities need to render inside the `@layer utilities` directive.
Tailwind will automatically create responsive classes for any utilities rendered inside the `@responsive` directive.
If you need Tailwind to create pseudo-class variant classes (e.g. for :hover or :focus), use the `@variants` directive.
The `@variants` and `@responsive` directives can be combined.

https://tailwindcss.com/docs/adding-new-utilities#generating-responsive-variants
https://tailwindcss.com/docs/adding-new-utilities#generating-pseudo-class-variants

 */

@layer utilities {

    @responsive {

        .gpu {
            @include gpu();
        }

        .anti {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .round {
            border-radius: 100%;
        }

        .full {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        .fullheight {
            height: 100vh;
            @supports (--custom:property) {
                height: calc(var(--vh, 1vh) * 100);
            }
        }

        .min-fullheight {
            min-height: 100vh;
            @supports (--custom:property) {
                min-height: calc(var(--vh, 1vh) * 100);
            }
        }

        .clear {
            &:before, &:after {
                content: "";
                display: table;
            }

            &:after {
                clear: both;
            }
        }

        .center {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .center-x {
            left: 50%;
            transform: translateX(-50%);
        }

        .center-y {
            top: 50%;
            transform: translateY(-50%);
        }

        .object-cover {
            object-fit: cover;

            html.no-objectfit & {
                object-fit: none !important;
                width: auto;
                height: auto;
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: none;
                max-height: none;
                min-width: 100%;
                min-height: 100%;
            }
        }

        .object-contain {
            object-fit: contain;
            width: 100%;
            height: 100%;

            html.no-objectfit & {
                object-fit: none !important;
                width: auto;
                height: auto;
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
            }
        }

        .placed-wrap {
            position: relative;
            height: 0;
        }

        .placed-image {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;

            &--safe {
                position: absolute;
                max-width: none;
                width: calc(100% + 4px);
                height: calc(100% + 4px);
                left: -2px;
                top: -2px;
            }
        }

        .scrollable {
            @include scrollable();
        }

        .scrollable-x {
            @include scrollableX();
        }


        .top-padding {
            padding-top: 56px;

            @include from(l) {
                padding-top: 80px;
            }
        }

        .link {
            border-bottom: 2px solid $colorGreenLink;
            transition: border-bottom-color 0.25s ease-out;

            &:hover {
                border-bottom-color: transparent !important;
            }

            .bg-black & {
                border-bottom: 2px solid $colorGreen;
            }

            &.thin {
                border-bottom-width: 1px;
            }
        }

        .image-zoom {
            img {
                will-change: transform, opacity;
                transition: opacity 0.5s ease-in-out, transform 0.6s $easeOutQuad;
            }

            &:hover, &:focus {
                img {
                    transform: scale(1.025);
                }
            }
        }

        .ellipsis {
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            max-width: 100%;
            white-space: nowrap;
        }

    }

}
