.menu {
    &:target {
        height: 100%;
    }
    
    a {
        transition: color 0.1s linear;
    }
    
    &__close-btn {
        top: 30px;
        right: gutter() - 10px;
        
        @include from(s) {
            right: gutter('s') - 10px;
        }
        @include from(sp) {
            right: gutter('sp') - 10px;
        }
        @include from(m) {
            right: gutter('m') - 10px;
        }
        @include from(mp) {
            right: gutter('mp') - 10px;
        }
        @include from(l) {
            top: 70px;
            right: gutter('l') - 10px;
        }
        @include from(lp) {
            right: gutter('lp') - 10px;
        }
        @include from(xl) {
            right: gutter('xl') - 10px;
        }
    }
}
