.block-signatures {
    &__progressbar {
        transform-origin: top left;
        transform: scaleX(0);
    }

    &__tooltip {
        position: absolute;
        left: 0%;
        bottom: -10px;
        white-space: nowrap;
        opacity: 0;
        span {
            transform: translateX(-50%);
            &:after {
                bottom: calc(100% - 1px);
                left: 50%;
                border: solid transparent;
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: transparent;
                border-bottom-color: $colorGrayStone;
                border-width: 9px;
                margin-left: -9px;
            }
        }
    }

    &__background {
        @include until(m) {
            width: 100vw;
            left: outerMargin(min)/2*-1;
        }
    }
}
