.page-header {
    .is-nav-transparent & {
        &:after {
            content: ' ';
            display: block;
            position: absolute;
            width: 100%;
            height: 98px;
            left: 0;
            top: 0;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);

            @include from(l) {
                height: 135px;
            }
        }
    }

    &__bar {
        height: 56px;
        color: $colorWhite;
        background-color: $colorBlack;
        transition: background-color 0.2s ease;
        transition-property: background-color, transform;
        transition-duration: 0.5s, 0.5s;
        transition-delay: 0s, 0s;
        transition-timing-function: ease, $easeOutQuint;

        .is-nav-light & {
            color: $colorBlack;
            background-color: $colorWhite;
        }
        .is-nav-transparent & {
            background-color: rgba(0, 0, 0, 0);
        }

        .is-nav-scrolled & {
            .is-nav-transparent & {
                background-color: $colorBlack;
                transition-delay: 0.4s, 0s;
            }
        }

        opacity: 0;

        .no-js &,
        .js-error & {
            opacity: 1;
        }

        @include from(l) {
            height: 80px;
        }

        &.is-hidden {
            transform: translateY(-57px);

            @include from(l) {
                transform: translateY(-81px);
            }
        }
    }

    &__bar-inner {
        max-width: none !important;

        @include from(lp) {
            padding-left: 80px;
            padding-right: 80px;
        }
    }

    &__logo {
        overflow: hidden;

        width: 30px;

        @include from(l) {
            width: auto;
        }

        svg {
            width: 152px;
            height: 30px;

            @include from(l) {
                width: 253px;
                height: 49px;
            }
        }

        &.-lang-en {
            svg {
                width: 152px;
                height: 30px;

                @include from(l) {
                    width: 255px;
                    height: 53px;
                }
            }
        }

    }

    &__search-btn {
        width: 44px;

        @include from(l) {
            width: 72px;
            svg {
                width: 24px;
                height: 28px;
            }
        }
    }

    &__menu-btn {
        width: 44px;

        @include from(l) {
            width: 72px;
        }

        .l1, .l2, .l3 {
            width: 14px;
            height: 3px;
            left: 15px;

            @include from(l) {
                width: 24px;
                height: 5px;
                left: 24px;
            }
        }

        .l1 {
            top: 20px;

            @include from(l) {
                top: 26px;
            }
        }
        .l2 {
            top: 26px;

            @include from(l) {
                top: 37px;
            }
        }
        .l3 {
            top: 32px;

            @include from(l) {
                top: 48px;
            }
        }
    }

    &__cart-btn {
        width: 61px;

        @include from(l) {
            width: 82px;
        }

        svg {
            width: 24px;
            height: 22px;
            top: 17px;
            left: 17px;

            @include from(l) {
                width: 35px;
                height: 33px;
                top: 23px;
                left: 23px;
            }
        }

        &-count {
            padding: 3px;
            min-width: 16px;
            left: 32px;
            top: 10px;

            @include from(l) {
                padding: 4px;
                min-width: 24px;
                left: 44px;
                top: 12px;
            }
        }
    }
}
