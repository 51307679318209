.block-numbers {

    &:last-child {
        margin-bottom: 0;
    }

    &--double {
        img {
            margin-left: gutter(min) * -0.5;
            width: calc(100% + #{gutter(min)});
            max-width: none;
            @include from(m) {
                margin-left: gutter(m) * -0.5;
                width: calc(100% + #{gutter(m)});
            }
            @include from(l) {
                width: 50%;
                margin-left: 0;
            }
        }
        &:last-child img {
            @include from(l) {
                left: 50%;
            }
        }
    }
}
