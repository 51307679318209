.button {
    $this: &;

    height: 54px;
    min-width: 54px;

    &[disabled],
    &[aria-disabled] {
        opacity: 0.33;
        cursor: default;
    }

    &__wrap {
        padding: 0 32px;
    }

    &__label {

    }

    &__icon {
        width: 8px;
        height: 16px;
        transition-property: transform;
        transition-duration: 0.5s;
        transition-timing-function: $easeInOutQuart;
        top: -1px;
    }

    @media (hover: hover) {
        &:not(:disabled):not([aria-disabled="true"]):hover {
            .button__icon {
                transform: translateX(5px);
            }
        }
    }
}
