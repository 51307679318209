.hero-standard {

    &__image-wrap {
        .-imagebelow & {
            padding-bottom: (3/4) * 100%;
            
            @include from(m) {
                padding-bottom: (9/16) * 100%;
            }
        }
    }
    
    &__image-wrap-bg {
        .-imagebelow & {
            height: calc(50% + 24px);
            width: 500%;
            position: absolute;
            left: -200%;
            bottom: -24px;
        }
    }
    
    &__image-inner {
        .-imageright & {
            padding-bottom: (3/4) * 100%;
            
            @include from(m) {
                padding-bottom: 0;
            }
        }
    }
}
