// Missing something? It's probably been converted to a custom Tailwind utility
// Check ./src/css/tailwind/utilities

// There's also a chance the utility class you're looking for has been removed, because its use case is easily covered by default utilities in Tailwind
// https://tailwindcss.com/docs

.u {
    &-wrap {
        width: 100%;
        max-width: maxWidth() + (outerMargin()*2);
        padding-left: outerMargin();
        padding-right: outerMargin();
        margin-left: auto;
        margin-right: auto;

        @each $breakpoint in map-keys($breakpoints) {
            @include from($breakpoint) {
                padding-left: outerMargin($breakpoint);
                padding-right: outerMargin($breakpoint);
                max-width: maxWidth($breakpoint) + (outerMargin($breakpoint)*2);
            }
        }
    }

    &-bleed-gutter {
        @include from(l) {
            &-left {
                transform: translateX(gutter(l) * -1);
                width: calc(100% + #{gutter(l)});
            }
            &-right {
                width: calc(100% + #{gutter(l)});
            }
            &-both {
                transform: translateX(gutter(l) * -1);
                width: calc(100% + #{gutter(l)} * 2);
            }
        }
    }

    &-pad-gutter-all {
        padding: gutter(min);

        @include from(s) {
            padding: gutter(s);
        }
        @include from(sp) {
            padding: gutter(sp);
        }
        @include from(m) {
            padding: gutter(m);
        }
        @include from(mp) {
            padding: gutter(mp);
        }
        @include from(l) {
            padding: gutter(l);
        }
        @include from(lp) {
            padding: gutter(lp);
        }
        @include from(xl) {
            padding: gutter(xl);
        }
    }

    &-pad-gutter-y {
        padding-top: gutter(min);
        padding-bottom: gutter(min);

        @include from(s) {
            padding-top: gutter(s);
            padding-bottom: gutter(s);
        }
        @include from(sp) {
            padding-top: gutter(sp);
            padding-bottom: gutter(sp);
        }
        @include from(m) {
            padding-top: gutter(m);
            padding-bottom: gutter(m);
        }
        @include from(mp) {
            padding-top: gutter(mp);
            padding-bottom: gutter(mp);
        }
        @include from(l) {
            padding-top: gutter(l);
            padding-bottom: gutter(l);
        }
        @include from(lp) {
            padding-top: gutter(lp);
            padding-bottom: gutter(lp);
        }
        @include from(xl) {
            padding-top: gutter(xl);
            padding-bottom: gutter(xl);
        }
    }
}
