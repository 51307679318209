.hero-media {
    &:after {
        content: " ";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
        background: linear-gradient(to top, rgba(0, 0, 0, 1) 3%, rgba(0, 0, 0, 0) 70%);
    }
    
    &__text-inner {
        max-width: none !important;
        
        @include from(lp) {
            padding-left: 80px;
            padding-right: 80px;
        }
    }
}
