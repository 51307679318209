.newsletter-popup {
    &__inner {
        width: 100%;
        max-width: 600px;
        box-shadow: 0 0 100px rgba(255, 255, 255, 0.2);
    }
    
    &__close-btn {
        width: 50px;
        height: 50px;
        top: -25px;
        right: -25px;
    }
    
    &__email-input {
        height: 54px;
    }
}
