/* ==============================
    Basic/generic global styles
 ============================== */

body {
    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;
}

.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.no-js {
    img.lazyload {
        display: none;
    }
}

.disable-animations * {
    transition-duration: 0s !important;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

input.hide-clear[type=search]::-ms-clear,
input.hide-clear[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
