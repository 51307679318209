.wysiwyg {
    p, ul, ol, blockquote {
        margin-bottom: 28px;

        @include from(l) {
            margin-bottom: 32px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul, ol, blockquote {
        text-align: left;
    }

    ul {
        list-style: none;
    }

    ol {
        list-style: decimal;
    }

    ul, ol {
        padding-left: 1em;
    }

    li {
        display: list-item;
        padding-left: 0.3em;
        margin-bottom: 0.5em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul li {
        display: block;
        position: relative;

        &:before {
            left: -1em;
            top: calc(0.5em - 4px);
            content: '';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border: solid transparent;
            border-left-color: $colorGreen;
            border-width: 10px;
        }
    }

    b, strong {
        font-weight: 700;
    }

    i, em {
        font-style: italic;
    }

    h2 {
        margin-bottom: 16px;
        margin-top: 40px;

        @include from(l) {
            margin-top: 80px;
        }

        &:first-child {
            margin-top: 0 !important;
        }
    }

    blockquote {
        position: relative;
        padding-left: 24px;

        &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 4px;
            height: 100%;
            background: $colorGreen;
        }

        @include from(l) {
            padding-left: 0;
            &:before {
                left: -36px;
            }
        }
    }

    &.blockqoute-fix blockquote {
        @include from(l) {
            padding-left: 32px;
            &:before {
                left: 0;
            }
        }
    }

    a.footnote-link {
        color: $colorGreenLink;
        position: relative;
        top: -0.4em;
        display: inline-block;
        padding: 0 2px 0 3px;
        font-size: 80%;
        font-weight: 500;
        line-height: 1.3;
        border-bottom: none !important;
    }

    a {
        border-bottom: 2px solid $colorGreenLink;
        transition: border-bottom-color 0.25s ease-out;

        &:hover {
            border-bottom-color: transparent !important;
        }

        .bg-black & {
            border-bottom: 2px solid $colorGreen;
        }
    }

    &.dropcaps {
        p:first-of-type::first-letter {
            font-family: $fontFalster;
            font-weight: 500;
            font-size: 46px;
            font-style: italic;
            line-height: 0;
            padding: 0.65em 0.15em 0.2em 0;
            float: left;

            @include from(l) {
                font-size: 65px;
                padding: 0.6em 0.15em 0.2em 0;
            }
        }
    }

    &.collapsed {
        *:not(:first-child) {
            display: none;
        }
    }
}

.wysiwyg-simple {
    b, strong {
        font-weight: 700;
    }

    i, em {
        font-style: italic;
    }
}
