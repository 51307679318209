.block-teaser-collage {

    &__image {
        @include until(m) {
            margin-left: -16px;
            width: calc(100% + 32px);
        }
    }

    &-text {
        box-decoration-break: clone;
    }
}
