/**
========================
Custom Tailwind components
https://tailwindcss.com/docs/extracting-components
========================
 */

@layer components {

    /*.btn {
        @apply bg-indigo hover:bg-indigo-700 sm:text-lg;

        @screen m {
            ...
        }
    }*/
    .h1 {
        @apply font-falster font-medium italic text-36 leading-44 l:text-48 l:leading-60;
    }

    .h2, .wysiwyg h2 {
        @apply font-falster font-medium italic text-26 leading-36 l:text-32 l:leading-44;
    }

    .h3, .wysiwyg h3 {
        @apply font-falster font-medium italic text-20 leading-28 l:text-24 l:leading-36;
    }

    .body {
        @apply text-16 leading-28 l:text-20 l:leading-32;
    }

    .body-small {
        @apply text-14 leading-24 l:text-16;
    }

    .intro {
        @apply font-medium italic text-20 leading-28 l:text-24 l:leading-36;
    }

    .caption {
        @apply text-12 leading-20 l:text-16 l:leading-24;
    }

    .text-pretty {
        text-wrap: pretty;
    }
    
    .text-balance {
        text-wrap: balance;
    }
    
    .breadcrumb {
        @apply uppercase font-medium text-14 leading-24 l:text-16;
        a {
            border-bottom: 2px solid transparent;
            transition: border-bottom-color 0.25s ease-out;

            &:hover {
                border-bottom-color: currentColor;
            }
        }
    }

    .quote, .wysiwyg blockquote {
        @apply font-falster text-20 leading-28 l:text-24 l:leading-36;
    }

    .preamble {
        @apply font-falster uppercase text-12 leading-28 l:text-14 l:leading-32;
        letter-spacing: 0.01em;
    }

    .meta {
        @apply text-12 leading-18 l:text-14;
    }

    .button-text {
        @apply font-falster font-medium uppercase text-16 leading-24;
        letter-spacing: 0.08em;
    }

    .big-text {
        @apply font-falster text-36 leading-40 m:text-56 m:leading-60 l:text-96 l:leading-108 font-medium italic;

        @supports (font-size: clamp(36px, 5.5vw, 96px)) {
            font-size: clamp(36px, 5.5vw, 96px) !important;
            line-height: 1.1 !important;
        }
    }

    .big-text-hover {
        [data-big-text-hover-main] {
            left: -5px;
            transition: color 0.2s 0.1s ease;

            @include from(sp) {
                left: -8px;
            }
            @include from(mp) {
                left: -10px;
            }
            @include from(lp) {
                left: -13px;
            }

            span {
                padding: 0 5px;
                z-index: 1;

                @include from(sp) {
                    padding: 0 8px;
                }
                @include from(mp) {
                    padding: 0 10px;
                }
                @include from(lp) {
                    padding: 0 13px;
                }

                &:before {
                    transition: transform 0.2s 0.1s cubic-bezier(0.21, 0, 0.25, 1);
                    content: ' ';
                    display: block;
                    width: 100%;
                    height: calc(100% + 10px);
                    background: $colorGreen;
                    position: absolute;
                    left: 0;
                    top: -5px;
                    transform: scaleX(0);
                    transform-origin: top left;
                    z-index: -1;

                    .bg-green & {
                        background-color: $colorBlack;
                    }
                }
            }
        }

        &:hover {
            [data-big-text-hover-main] {
                color: $colorBlack;

                .bg-green & {
                    color: $colorWhite;
                }

                span:before {
                    transform: scaleX(1.015) translate3d(0, 0, 0);
                }
            }
        }
    }

    .medium-text {
        @apply font-falster text-36 leading-40 m:text-40 m:leading-48 l:text-48 l:leading-60 font-medium italic;

        @supports (font-size: clamp(36px, 3vw, 48px)) {
            font-size: clamp(36px, 3vw, 48px) !important;
            line-height: 1.1 !important;
        }
    }

    .medium-text-hover {
        [data-medium-text-hover-main] {
            left: -5px;
            transition: color 0.2s 0.1s ease;

            @include from(sp) {
                left: -6px;
            }
            @include from(mp) {
                left: -8px;
            }
            @include from(lp) {
                left: -10px;
            }

            span {
                padding: 0 5px;
                z-index: 1;

                @include from(sp) {
                    padding: 0 6px;
                }
                @include from(mp) {
                    padding: 0 8px;
                }
                @include from(lp) {
                    padding: 0 10px;
                }

                &:before {
                    transition: transform 0.2s 0.1s cubic-bezier(0.21, 0, 0.25, 1);
                    content: ' ';
                    display: block;
                    width: 100%;
                    height: calc(100% + 8px);
                    background: $colorGreen;
                    position: absolute;
                    left: 0;
                    top: -4px;
                    transform: scaleX(0);
                    transform-origin: top left;
                    z-index: -1;

                    .bg-green & {
                        background-color: $colorBlack;
                    }
                }
            }
        }

        &:hover {
            [data-medium-text-hover-main] {
                color: $colorBlack;

                .bg-green & {
                    color: $colorWhite;
                }

                span:before {
                    transform: scaleX(1.015);
                }
            }
        }
    }

    .triangles {

        svg {
            display: inline-block;
            width: auto;
            height: 0.55em;
            margin-right: 2px;

            &:last-child {
                margin-right: 0;
            }

            @include from(m) {
                margin-right: 4px;
            }

            @include from(l) {
                margin-right: 6px;
            }

            * {
                fill: currentColor;
            }
        }

        &.-small {
            svg {
                margin-right: 2px;

                &:last-child {
                    margin-right: 0;
                }

                @include from(m) {
                    margin-right: 2px;
                }

                @include from(l) {
                    margin-right: 2px;
                }
            }
        }
    }

    .highlight {
        box-shadow: inset 0 -0.45em 0 $colorGreen;
    }

    .play-icon {
        display: block;
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $colorBlack;

        path {
            fill: $colorGreen;
        }

        &.-col-invert {
            background-color: $colorGreen;

            path {
                fill: $colorBlack;
            }
        }

        @include from(m) {
            width: 80px;
            height: 80px;
        }

        svg {
            position: absolute;
            width: 18px;
            height: 16px;
            left: 14px;
            top: 12px;

            @include from(m) {
                width: 35px;
                height: 31px;
                left: 28px;
                top: 25px;
            }
        }
    }

    .select {
        appearance: none;
        position: relative;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='12' viewBox='0 0 24 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 0L0 0L12 12L24 0Z' fill='black'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: right 16px top 50%;
        background-size: 24px 24px;

        &::-ms-expand {
            display: none;
        }

        &:not(:disabled) {
            cursor: pointer;
        }
    }

    .radio-select {

        &__bullet {
            &:after {
                input:checked + & {
                    content: ' ';
                    display: block;
                    width: 18px;
                    height: 18px;
                    background: currentColor;
                    border-radius: 9999px;
                    position: absolute;
                    left: 4px;
                    top: 4px;
                }
            }
        }
    }

    .checkbox {
        &__mark {
            border: 2px solid currentColor;

            svg {
                opacity: 0;
            }

            input:checked + & {
                svg {
                    opacity: 1;
                }
            }
        }
    }

    .quantity-button {
        width: 54px;
        height: 54px;
        position: absolute;
        top: 0;
        text-align: center;

        &.-left {
            left: 0;
        }

        &.-right {
            right: 0;
        }
    }

    .wysiwyg a.primary-btn,
    .wysiwyg a .btn-action,
    .wysiwyg .btn-action a {
        @apply inline-block button-text leading-1 italic max-w-100 border-3 bg-green border-green text-black hover:bg-white transition-colors duration-200 select-none;
        height: 54px;
        min-width: 54px;
        padding: 17px 32px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .wysiwyg a.primary-btn:hover,
    .wysiwyg a:hover .btn-action,
    .wysiwyg .btn-action a:hover {
        border-color: $colorGreen !important;
    }

    .frontpage-area-link {
        transition: color 0.2s 0.1s ease;
        transition-property: color;
        transition-duration: 0.2s;
        transition-timing-function: ease;
        z-index: 1;

        @include from(m) {

        }

        &:hover {
            color: $colorBlack;

            &:before {
                transform: scaleX(1);
            }
        }

        &:before {
            transition: transform 0.2s 0.1s cubic-bezier(0.21, 0, 0.25, 1);
            content: ' ';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            transform-origin: top left;
            transform: scaleX(0);
            background: $colorGreen;
            z-index: -1;

            &:hover {
            }
        }
    }

    .footnotes {
        border-top: 1px solid #606060;

        li {
            margin-bottom: 1em;
        }

        &__back {
            font-weight: 500;
            line-height: 1;
            margin-right: 1em;
            padding: 4px 3px 2px;
            border-bottom: 2px solid $colorGreenLink;
        }
    }

    .frontpage__map-header {
        margin-left: 32px;
        @include from(1372px) {
            margin-left: 0;
        }
    }

    .collabsible-text-toggle {
        &[aria-expanded="true"] {
            margin-top: 28px;
            @include from(l) {
                margin-top: 32px;
            }
        }
    }


}
