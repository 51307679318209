.sticky-menu {
    display: none;
    position: fixed;
    bottom: 40px;
    right: 0;
    font-weight: 500;
    font-style: italic;
    font-size: 12px;
    line-height: 40px;
    @include from(m) {
        bottom: 60px;
        right: 32px;
    }
    @include from(l) {
        bottom: 100px;
        right: 69px;
    }
    &__cta {
        $scope: &;
        display: block;
        position: relative;
        height: 40px;
        background: $colorGreen;
        color: $colorBlack;
        transition: all 0.25s ease-out;
        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
        }
        &-text, &-hover {
            display: none;
        }
        @include from(m) {
            padding: 0 15px;
            &-icon {
                display: none;
            }
            &-text {
                display: block;
            }
            &-hover {
                display: block;
                background: $colorBlack;
                opacity: 0;
                transition: all 0.25s ease-out;
            }
        }
        &:hover, &:focus {
            background: $colorBlack;
            color: $colorGreen;
            #{$scope}-hover {
                opacity: 1;
            }
        }
    }

    &__dialog {
        $this: &;
        display: none;
        @include from(m) {
            display: block;
            position: relative;
            background: $colorBlack;
            color: $colorWhite;
        }
        &-button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            background: inherit;
            color: inherit;
            transition: all 0.25s ease-out;
            svg {
                margin-bottom: 2px;
            }
            &[aria-expanded="true"] {
                background: $colorGreen;
                color: $colorBlack;
                & + #{$this}-popup {
                    display: block;
                }
            }
        }
        &-popup {
            display: none;
            position: absolute;
            bottom: 100%;
            right: 0;
            background: inherit;
            color: inherit;
            a {
                display: block;
                white-space: nowrap;
                padding: 0 13px;
            }
        }
    }

    &__about {
        display: none;
        @include from(m) {
            display: block;
            padding: 0 13px;
            background: $colorBlack;
            color: $colorWhite;
            transition: all 0.25s ease-out;
            &:hover, &:focus {
                background: $colorGreen;
                color: $colorBlack;
            }
        }
    }
}
